.signupCover {
  width: 100%;
  height: 80vh;
  margin-top: 60px;
}
.boxWrapper {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}
.pageWrapper{
  width: 55%;
}
.pageBox{
  width: 45%;
}
.formWrapper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  height: 85%;
}
@media (max-width: 1000px) {
  .pageWrapper {
    display: none;
  }
  .pageBox {
    width: 100%;
  }
}

.modal-box {
    border-radius: 12px;
    width: 70%;
  }
  
  .modal-content {
    padding: 16px 24px;
  }
  
  @media (max-width: 600px) {
    .modal-box {
      width: 100%;
      padding: 16px; /* Better padding for mobile screens */
    }
  
    .modal-content {
      padding: 12px 16px; /* Slightly smaller padding for mobile */
    }
  
    .okay-button {
      width: 100%; /* Make okay button full-width on mobile */
    }
  }
  
  
  
  
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   font-family: 'Raleway', 'Montserrat', sans-serif !important;
   text-decoration: none;
}

html {
   scroll-behavior: smooth;
}

.focus:focus {
   border-color: #374da0;
   outline: 1px solid #374da0;
}

.css-bjwb-MuiStack-root {
   padding: 0 !important;
}

/* create a custom scrollbar */

::-webkit-scrollbar {
   width: 5px;
   height: 5px;
}
::-webkit-scrollbar-track {
   width: 5px;
   height: 5px;
   background-color: #e8e8e8;
}
::-webkit-scrollbar-thumb {
   background: #555;
}

/* firefox */
body {
   scrollbar-width: thin;
   scrollbar-color: #555;
}

.banner {
   background-color: #ffcc00;
   color: #333;
   padding: 5x;
   text-align: center;
   position: sticky;
   top: 0;
   width: 100%;
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
}

#close-banner {
   background: none;
   border: none;
   font-size: 18px;
   cursor: pointer;
   color: #333;
   margin-right: 10px;
}
#close-banner:hover {
   opacity: 0.8;
 }
.banner-message {
   flex-grow: 1; 
   text-align: center;
   font-weight: bold;
}

.grecaptcha-badge {
   right: auto !important;
   left: 0;
   width: 70px !important;

   -webkit-transition: width 0.5s ease-in-out !important;
   -moz-transition: width 0.5s ease-in-out !important;
   -o-transition: width 0.5s ease-in-out !important;
   transition: width 0.5s ease-in-out !important;

   &:hover {
       width: 256px !important;
   }
}
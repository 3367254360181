.avatarWrapper {
  display: grid;
  width: 60%;
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 1.7rem;
  margin-top: 2rem;
}
.avatarImage{
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.avatarUrl{
  width: 100%;
  height: 100%;
  cursor: pointer;
}

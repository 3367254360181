.termsLink{
    text-decoration: none;
    color: #374DA0;
}

.termsLink:hover{
    text-decoration: underline;
}

.nigeriaFlag{
    margin-top: 7px;
    margin-left: -12px;
}
/* .flickity-page-dots {
	display: none !important;
} */
/* Add spacing between Flickity slides */
.carousel-cell {
   margin-right: 10px;
}

/* Remove margin from the last slide to avoid extra space */
/* .flickity-slider .carousel-cell:last-child {
  margin-right: 0;
} */
.rotating-element {
   animation: rotate 4s linear infinite;
}

@keyframes rotate {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

/* smaller, dark, rounded square */
.flickity-button {
   background: #333;
}
.flickity-button:hover {
   background: #374da0;
}

.flickity-prev-next-button {
   width: 30px;
   height: 30px;
   /* border-radius: 5px; */
}
/* icon color */
.flickity-button-icon {
   fill: white;
}
/* position outside */
.flickity-prev-next-button.previous {
   left: -5%;
}
.flickity-prev-next-button.next {
   right: -5%;
}

.dot1{
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: #374DA0;
}
.dot2{
    background-color: #A1A1A1;
}
.pageHeader{
    font-size: clamp(1.5rem, 2vw, 3rem);
    margin-top: clamp(1rem, 2vw, 1.5rem);
}
.wrapper {
   display: grid;
   place-items: center;
   color: #374da0;
   margin-top: 50px;
}

.typing-demo {
   width: 23ch;
   animation: typing 2.5s steps(22), blink 0.5s step-end infinite alternate;
   white-space: nowrap;
   overflow: hidden;
   font-size: 2em;
   font-family: 'Raleway', 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 500;
}

@keyframes typing {
   from {
      width: 0;
   }
}

.onboardingCover {
   width: 100%;
   height: 80vh;
   margin-top: 60px;
}

.pageDescription {
   font-family: 'Raleway', 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 900;
   margin-top: 8px;
}

.privacyLink {
   font-family: 'Raleway', 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 600;
   margin-top: 60px;
}

.privacyPolicy {
   margin-left: 4px;
   color: #374da0;
   text-decoration: none;
}

.privacyPolicy:hover {
   text-decoration: underline;
}
.pageLink {
   text-decoration: none;
   color: #374da0;
   font-family: 'Raleway', 'Montserrat', sans-serif;
}
.LinkLogin {
   color: white;
   text-decoration: none;
}
